import useAuth from "@/hooks/auth";
import { formatDate } from "@/utils/datetime";
import {
  ArrowLeft,
  ArrowRight,
  BookOpen,
  PlayCircle,
  Play,
} from "@phosphor-icons/react";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { createRef, useEffect, useRef, useState } from "react";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import { register } from "swiper/element/bundle";
import VideoPlayer from "../VideoPlayer";
import LatestVideo from "../items/LatestVideo";
import useWindowSize from "@/hooks/useWindowSize";
import { fitIn } from "@/utils/imageHandler";
import { CONTENT_TYPES } from "@/utils/content";
import { useAtom } from "jotai";
import { loginPopup } from "@/Atoms";
import DummyLogo from "@/assets/images/lucidity-placeholder.png";

register();

const CarouselLatest = ({
  latest_article,
  latest_spotlight,
  latest_video,
  special_report,
  banners,
}) => {
  const swiperRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const navigationThumb = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);
  const paginationRef = useRef(null);
  const [isPlay, setPlay] = useState(false);
  const [playedUrl, setPlayedUrl] = useState("");
  const { user } = useAuth();
  const router = useRouter();
  const [showVideo, setShowVideo] = useState(false);
  const size = useWindowSize();
  const [openLoginPopup, setOpenLoginPopup] = useAtom(loginPopup);

  const autoplayDuration = 6000;

  const onHandlePlay = (url) => {
    setPlay(true);
    setPlayedUrl(url);
  };

  const onHandleStop = () => {
    setPlay(false);
    setPlayedUrl("");
  };

  useEffect(() => {
    setTimeout(() => {
      if (size.width > 1024) {
        setShowVideo(true);
      } else {
        setShowVideo(false);
      }
    }, 200);
  }, [size]);

  useEffect(() => {
    const params = {
      autoplay: {
        delay: autoplayDuration,
        disableOnInteraction: false,
      },
      effect: "fade",
      crossFade: true,
      slidesPerView: 1,
      autoHeight: true,
      allowTouchMove: true,
      loop: true,
      navigation: {
        nextEl: nextRef.current,
        prevEl: prevRef.current,
      },
      pagination: {
        el: ".pagination",
        clickable: true,
        bulletClass: "block w-2 h-2 rounded-full bg-white/20",
        bulletActiveClass: "bg-gradient-to-r from-yellow-1 to-red-1",
      },
      keyboard: true,
      on: {
        init(swiper) {
          updateProgress(swiper.realIndex);
        },
        slideChange(swiper) {
          updateProgress(swiper.realIndex);
        },
      },
    };
    Object.assign(swiperRef.current, params);
    swiperRef.current.initialize();
  }, []);

  const updateProgress = (index) => {
    if (paginationRef && paginationRef.current) {
      paginationRef.current.style.transitionDuration = `0ms`;
      paginationRef.current.style.transform = `translateX(-100%)`;
      navigationThumb?.current.forEach((item) => {
        if (item && item.current) {
          item.current.style.transitionDuration = `0ms`;
          item.current.style.transform = `translateX(-100%)`;
        }
      });
      if (navigationThumb && navigationThumb?.current) {
        if (navigationThumb?.current[index]?.current) {
          navigationThumb.current[
            index
          ].current.style.transitionDuration = `${autoplayDuration}ms`;
          navigationThumb.current[
            index
          ].current.style.transform = `translateX(0)`;
        }
      }

      if (paginationRef && paginationRef.current) {
        paginationRef.current.style.transitionDuration = `${autoplayDuration}ms`;
        paginationRef.current.style.transform = `translateX(0)`;
      }
    }
  };

  const navigateSlide = (index) => {
    swiperRef?.current.swiper.slideToLoop(index);
  };

  const goToLogin = (path) => {
    router.push(`/auth/login?redirect_to=${path}`);
  };

  return (
    <div className='container relative overflow-hidden text-white lg:pb-24'>
      {isPlay && <VideoPlayer url={playedUrl} onClose={onHandleStop} />}
      <swiper-container ref={swiperRef} init='false'>
        {/* Report */}
        {banners.map((banner, index) => (
          <swiper-slide
            key={`banner-slide-${index}`}
            class='bg-background grid place-items-end h-[540px] lg:h-auto'
          >
            {banner.banner_type === "podcast" && (
              <div className='relative flex flex-col h-full grid-cols-12 lg:grid'>
                <div className='self-center col-span-4 col-start-1 -mt-4 lg:pt-20 row-span-full'>
                  <div className='relative z-10 lg:max-w-4xl 3xl:max-w-6xl lg:mt-0'>
                    <div className='flex mb-2 lg:mb-4 text-[10px]'>
                      <span className='font-bold tracking-widest text-transparent uppercase bg-clip-text bg-gradient-to-r from-yellow-1 to-red-1'>
                        Latest Podcast Series
                      </span>
                      <span className='pl-3 ml-3 font-medium tracking-wider uppercase border-l opacity-50 border-white/40'>
                        {formatDate(banner.publish_date, "DD MMMM YYYY")}
                      </span>
                    </div>
                    <h2 className='text-3xl font-bold lg:text-4xl 2xl:text-5xl font-display'>
                      {banner.title}
                    </h2>
                    <div className='mt-3 2xl:mt-6 lg:flex lg:space-x-6'>
                      <div className='flex-1'>
                        <p className='text-sm leading-relaxed 2xl:text-base opacity-80'>
                          {banner.description}
                        </p>
                        {banner.logo_url && (
                          <div className='mt-5'>
                            <span className='block text-[10px] mb-2 opacity-60'>
                              Powered by :
                            </span>
                            <img
                              src={fitIn(banner.logo_url, 800)}
                              className='h-8'
                              alt='Banner Image'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='flex items-center mt-6 lg:mt-8'>
                      {!!!banner.is_coming_soon ? (
                        user ? (
                          <Link
                            legacyBehavior
                            href={`/podcasts-masterclasses/${banner.first_episode?.slug}`}
                          >
                            <a className='inline-flex items-center px-3.5 py-2.5 text-sm font-medium leading-none rounded-md bg-gradient-to-r from-yellow-1 to-red-1 gap-x-2'>
                              <Play weight='bold' className='text-lg' />
                              <span className='whitespace-nowrap'>
                                Watch Podcast
                              </span>
                            </a>
                          </Link>
                        ) : (
                          <div
                            onClick={() => setOpenLoginPopup(true)}
                            className='inline-flex items-center px-3.5 py-2.5 text-sm font-medium leading-none rounded-md bg-gradient-to-r from-yellow-1 to-red-1 gap-x-2 cursor-pointer'
                          >
                            <Play weight='bold' className='text-lg' />
                            <span className='whitespace-nowrap'>
                              Watch Podcast
                            </span>
                          </div>
                        )
                      ) : (
                        <div className='px-3.5 py-2.5 text-sm font-medium rounded-md text-white/70 bg-white/10'>
                          <span className='whitespace-nowrap'>Coming Soon</span>
                        </div>
                      )}
                      {banner.video_url && (
                        <button
                          type='button'
                          onClick={() => onHandlePlay(banner.video_url)}
                          className='inline-flex items-center pl-3 ml-3 space-x-2 text-sm border-l lg:text-base lg:pl-5 lg:ml-5 border-white/10'
                        >
                          <PlayCircle
                            weight='light'
                            className='text-2xl opacity-50'
                          />
                          <span>Watch Trailer</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className='relative self-center flex-1 order-first w-full col-span-7 col-start-6 lg:order-last lg:aspect-video lg:row-span-full'>
                  {showVideo ? (
                    <video
                      autoPlay
                      loop
                      muted
                      className='hidden lg:block'
                      poster={banner.thumbnail_url || DummyLogo}
                      preload='none'
                    >
                      <source
                        id='video'
                        src={banner.video_url}
                        type='video/mp4'
                      />
                    </video>
                  ) : (
                    <Image
                      className='lg:hidden'
                      src={fitIn(banner.thumbnail_url || DummyLogo, 600)}
                      alt=''
                      layout='fill'
                      objectFit='cover'
                    />
                  )}
                  <span className='absolute inset-0 w-full h-full bg-gradient-to-b from-background from-[0%] via-transparent to-background'></span>
                  <span className='absolute inset-0 w-full h-full bg-gradient-to-r from-background from-[0%] via-transparent to-background'></span>
                </div>
              </div>
            )}
            {banner.content_type?.name === CONTENT_TYPES.SPECIAL_REPORTS && (
              <div className='relative flex flex-col h-full grid-cols-12 lg:grid'>
                <div className='self-center col-span-4 col-start-1 -mt-4 lg:pt-20 row-span-full'>
                  <div className='relative z-10 lg:max-w-4xl 3xl:max-w-6xl lg:mt-0'>
                    <div className='flex mb-2 lg:mb-4 text-[10px]'>
                      <span className='font-bold tracking-widest text-transparent uppercase bg-clip-text bg-gradient-to-r from-yellow-1 to-red-1'>
                        Latest Special Report
                      </span>
                      <span className='pl-3 ml-3 font-medium tracking-wider uppercase border-l opacity-50 border-white/40'>
                        {formatDate(banner.publish_date, "DD MMMM YYYY")}
                      </span>
                    </div>
                    <h2 className='text-3xl font-bold lg:text-4xl 2xl:text-5xl font-display'>
                      {banner.title}
                    </h2>
                    <div className='mt-3 2xl:mt-6 lg:flex lg:space-x-6'>
                      <div className='flex-1'>
                        <p className='text-sm leading-relaxed 2xl:text-base opacity-80'>
                          {banner.excerpt}
                        </p>
                        {banner.logo_url && (
                          <div className='mt-5'>
                            <span className='block text-[10px] mb-2 opacity-60'>
                              Powered by :
                            </span>
                            <img
                              src={fitIn(banner.logo_url, 800)}
                              className='h-8'
                              alt='Banner Image'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='flex items-center mt-6 lg:mt-8'>
                      <Link legacyBehavior href={`/reports/${banner.slug}`}>
                        <a className='inline-flex items-center px-3.5 py-2.5 text-sm font-medium leading-none rounded-md bg-gradient-to-r from-yellow-1 to-red-1 gap-x-2'>
                          <BookOpen weight='bold' className='text-lg' />
                          <span className='whitespace-nowrap'>
                            Read Full Report
                          </span>
                        </a>
                      </Link>
                      {banner.video_bg_url && (
                        <button
                          type='button'
                          onClick={() => onHandlePlay(banner.video_bg_url)}
                          className='inline-flex items-center pl-3 ml-3 space-x-2 text-sm border-l lg:text-base lg:pl-5 lg:ml-5 border-white/10'
                        >
                          <PlayCircle
                            weight='light'
                            className='text-2xl opacity-50'
                          />
                          <span>Watch Trailer</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className='relative self-center flex-1 order-first w-full col-span-7 col-start-6 lg:order-last lg:aspect-video lg:row-span-full'>
                  {showVideo ? (
                    <video
                      autoPlay
                      loop
                      muted
                      className='hidden lg:block'
                      poster={banner.background_image_url || DummyLogo}
                      preload='none'
                    >
                      <source
                        id='video'
                        src={banner.video_bg_url}
                        type='video/mp4'
                      />
                    </video>
                  ) : (
                    <Image
                      className='lg:hidden'
                      src={fitIn(banner.background_image_url || DummyLogo, 600)}
                      alt=''
                      layout='fill'
                      objectFit='cover'
                    />
                  )}
                  <span className='absolute inset-0 w-full h-full bg-gradient-to-b from-background from-[0%] via-transparent to-background'></span>
                  <span className='absolute inset-0 w-full h-full bg-gradient-to-r from-background from-[0%] via-transparent to-background'></span>
                </div>
              </div>
            )}
            {banner.content_type?.name === CONTENT_TYPES.VIDEOS && (
              <LatestVideo latest_video={banner} />
            )}
            {banner.content_type?.name === CONTENT_TYPES.ARTICLES && (
              <div className='relative flex flex-col h-full grid-cols-12 lg:grid'>
                <div className='self-center col-span-4 col-start-1 -mt-4 lg:pt-20 row-span-full'>
                  <div className='relative z-10 lg:max-w-4xl 3xl:max-w-6xl'>
                    <div className='flex mb-2 lg:mb-4 text-[10px]'>
                      <span className='font-bold tracking-widest text-transparent uppercase bg-clip-text bg-gradient-to-r from-yellow-1 to-red-1'>
                        Latest Article
                      </span>
                      <span className='pl-3 ml-3 font-medium tracking-wider uppercase border-l opacity-50 border-white/40'>
                        {formatDate(banner.publish_date, "DD MMMM YYYY")}
                      </span>
                    </div>
                    <p className='text-3xl font-bold lg:text-4xl 2xl:text-5xl font-display'>
                      {banner.title}
                    </p>
                    <p className='mt-3 text-sm leading-relaxed 2xl:text-base opacity-80 2xl:mt-6'>
                      {banner.meta_desc}
                    </p>
                    <Link legacyBehavior href={`/articles/${banner.slug}`}>
                      <a className='inline-flex items-center px-3.5 py-2.5 text-sm font-medium leading-none rounded-md bg-gradient-to-r from-yellow-1 to-red-1 gap-x-2 mt-5 lg:mt-8'>
                        <BookOpen weight='bold' className='text-lg' />
                        <span>Read Article</span>
                      </a>
                    </Link>
                  </div>
                </div>
                <div className='relative self-center flex-1 order-first w-full col-span-7 col-start-6 lg:order-last lg:aspect-video lg:row-span-full'>
                  <Image
                    src={fitIn(banner.thumbnail_url || DummyLogo, 500)}
                    layout='fill'
                    objectFit='cover'
                    alt=''
                  />
                  <span className='absolute inset-0 w-full h-full bg-gradient-to-b from-background from-[0%] via-transparent to-background'></span>
                  <span className='absolute inset-0 w-full h-full bg-gradient-to-r from-background from-[0%] via-transparent to-background'></span>
                </div>
              </div>
            )}
            {banner.content_type?.name === CONTENT_TYPES.OPINIONS && (
              <div className='relative flex flex-col h-full grid-cols-12 lg:grid'>
                <div className='self-center col-span-4 col-start-1 -mt-4 lg:pt-20 row-span-full'>
                  <div className='relative z-10 lg:max-w-4xl 3xl:max-w-6xl'>
                    <div className='flex mb-2 lg:mb-4 text-[10px]'>
                      <span className='font-bold tracking-widest text-transparent uppercase bg-clip-text bg-gradient-to-r from-yellow-1 to-red-1'>
                        Latest Opinion
                      </span>
                      <span className='pl-3 ml-3 font-medium tracking-wider uppercase border-l opacity-50 border-white/40'>
                        {formatDate(banner.publish_date, "DD MMMM YYYY")}
                      </span>
                    </div>
                    <p className='text-3xl font-bold lg:text-4xl 2xl:text-5xl font-display'>
                      {banner.title}
                    </p>
                    <p className='mt-3 text-sm leading-relaxed 2xl:text-base opacity-80 2xl:mt-6'>
                      {banner.meta_desc}
                    </p>
                    <Link legacyBehavior href={`/opinions/${banner.slug}`}>
                      <a className='inline-flex items-center px-3.5 py-2.5 text-sm font-medium leading-none rounded-md bg-gradient-to-r from-yellow-1 to-red-1 gap-x-2 mt-5 lg:mt-8'>
                        <BookOpen weight='bold' className='text-lg' />
                        <span>Read Opinion</span>
                      </a>
                    </Link>
                  </div>
                </div>
                <div className='relative self-center flex-1 order-first w-full col-span-7 col-start-6 lg:order-last lg:aspect-video lg:row-span-full'>
                  <Image
                    src={fitIn(banner.thumbnail_url || DummyLogo, 500)}
                    layout='fill'
                    objectFit='cover'
                    alt=''
                  />
                  <span className='absolute inset-0 w-full h-full bg-gradient-to-b from-background from-[0%] via-transparent to-background'></span>
                  <span className='absolute inset-0 w-full h-full bg-gradient-to-r from-background from-[0%] via-transparent to-background'></span>
                </div>
              </div>
            )}
            {banner.content_type?.name === CONTENT_TYPES.SPOTLIGHTS && (
              <div className='relative flex flex-col h-full grid-cols-12 lg:grid'>
                <div className='self-center col-span-4 col-start-1 -mt-4 lg:pt-20 row-span-full'>
                  <div className='relative z-10 lg:max-w-4xl 3xl:max-w-6xl'>
                    <div className='flex mb-2 lg:mb-4 text-[10px]'>
                      <span className='font-bold tracking-widest text-transparent uppercase bg-clip-text bg-gradient-to-r from-yellow-1 to-red-1'>
                        Latest Spotlight
                      </span>
                      <span className='pl-3 ml-3 font-medium tracking-wider uppercase border-l opacity-50 border-white/40'>
                        {formatDate(banner.publish_date, "DD MMMM YYYY")}
                      </span>
                    </div>
                    <p className='text-3xl font-bold lg:text-4xl 2xl:text-5xl font-display'>
                      {banner.title}
                    </p>
                    <p className='mt-3 text-sm leading-relaxed 2xl:text-base opacity-80 2xl:mt-6'>
                      {banner.meta_desc}
                    </p>
                    <Link legacyBehavior href={`/spotlights/${banner.slug}`}>
                      <a className='inline-flex items-center px-3.5 py-2.5 text-sm font-medium leading-none rounded-md bg-gradient-to-r from-yellow-1 to-red-1 gap-x-2 mt-5 lg:mt-8'>
                        <BookOpen weight='bold' className='text-lg' />
                        <span>Read Spotlight</span>
                      </a>
                    </Link>
                  </div>
                </div>
                <div className='relative self-center flex-1 order-first w-full col-span-7 col-start-6 lg:order-last lg:aspect-video lg:row-span-full'>
                  <Image
                    src={fitIn(banner.thumbnail_url || DummyLogo, 600)}
                    layout='fill'
                    objectFit='cover'
                    alt=''
                  />
                  <span className='absolute inset-0 w-full h-full bg-gradient-to-b from-background from-[0%] via-transparent to-background'></span>
                  <span className='absolute inset-0 w-full h-full bg-gradient-to-r from-background from-[0%] via-transparent to-background'></span>
                </div>
              </div>
            )}
          </swiper-slide>
        ))}
      </swiper-container>
      <div className='relative z-10 hidden w-3/5 ml-auto -mt-10 lg:grid'>
        <div className='grid grid-cols-4 gap-4 ml-auto text-white 2xl:gap-5'>
          {banners.map((banner, index) => (
            <div key={`thumbnail-${index}`}>
              {banner.banner_type === "podcast" && (
                <div
                  onClick={() => navigateSlide(index)}
                  className='relative flex items-center pt-3 cursor-pointer gap-x-3'
                >
                  <div className='absolute inset-0 h-0.5 overflow-hidden bg-white/10'>
                    <span
                      ref={navigationThumb.current[index]}
                      className='absolute inset-0 w-full h-full ease-linear -translate-x-full bg-gradient-to-tr from-yellow-1 to-red-1'
                    ></span>
                  </div>
                  <div className='relative aspect-[170/227] h-20 2xl:h-24 rounded overflow-hidden flex-shrink-0'>
                    <Image
                      src={fitIn(banner.thumbnail_url || DummyLogo, 400)}
                      alt=''
                      layout='fill'
                      objectFit='cover'
                    />
                  </div>
                  <div className='-mt-2'>
                    <p className='mb-1.5 leading-none inline-block text-[10px] font-semibold text-transparent bg-clip-text bg-gradient-to-tr from-yellow-1 to-red-1'>
                      Podcast Series
                    </p>
                    <p className='text-xs font-medium leading-tight 2xl:text-sm line-clamp-3'>
                      {banner.title}
                    </p>
                  </div>
                </div>
              )}
              {banner.content_type?.name === CONTENT_TYPES.SPECIAL_REPORTS && (
                <div
                  onClick={() => navigateSlide(index)}
                  className='relative flex items-center pt-3 cursor-pointer gap-x-3'
                >
                  <div className='absolute inset-0 h-0.5 overflow-hidden bg-white/10'>
                    <span
                      ref={navigationThumb.current[index]}
                      className='absolute inset-0 w-full h-full ease-linear -translate-x-full bg-gradient-to-tr from-yellow-1 to-red-1'
                    ></span>
                  </div>
                  <div className='relative aspect-[170/227] h-20 2xl:h-24 rounded overflow-hidden flex-shrink-0'>
                    <Image
                      src={fitIn(banner.cover_image_url || DummyLogo, 400)}
                      alt=''
                      layout='fill'
                      objectFit='cover'
                    />
                  </div>
                  <div className='-mt-2'>
                    <p className='mb-1.5 leading-none inline-block text-[10px] font-semibold text-transparent bg-clip-text bg-gradient-to-tr from-yellow-1 to-red-1'>
                      Special Report
                    </p>
                    <p className='text-xs font-medium leading-tight 2xl:text-sm line-clamp-3'>
                      {banner.title}
                    </p>
                  </div>
                </div>
              )}
              {banner.content_type?.name === CONTENT_TYPES.VIDEOS && (
                <div
                  onClick={() => navigateSlide(index)}
                  className='relative flex items-center pt-3 cursor-pointer gap-x-3'
                >
                  <div className='absolute inset-0 h-0.5 overflow-hidden bg-white/10'>
                    <span
                      ref={navigationThumb.current[index]}
                      className='absolute inset-0 w-full h-full ease-linear -translate-x-full bg-gradient-to-tr from-yellow-1 to-red-1'
                    ></span>
                  </div>
                  <div className='relative aspect-[170/227] h-20 2xl:h-24 rounded overflow-hidden flex-shrink-0'>
                    <Image
                      src={fitIn(banner.thumbnail_url || DummyLogo, 400)}
                      alt=''
                      layout='fill'
                      objectFit='cover'
                    />
                  </div>
                  <div className='-mt-2'>
                    <p className='mb-1.5 leading-none inline-block text-[10px] font-semibold text-transparent bg-clip-text bg-gradient-to-tr from-yellow-1 to-red-1'>
                      Latest Videos
                    </p>
                    <p className='text-xs font-medium leading-tight 2xl:text-sm line-clamp-3'>
                      {banner.title}
                    </p>
                  </div>
                </div>
              )}
              {banner.content_type?.name === CONTENT_TYPES.ARTICLES && (
                <div
                  onClick={() => navigateSlide(index)}
                  className='relative flex items-center pt-3 cursor-pointer gap-x-3'
                >
                  <div className='absolute inset-0 h-0.5 overflow-hidden bg-white/10'>
                    <span
                      ref={navigationThumb.current[index]}
                      className='absolute inset-0 w-full h-full ease-linear -translate-x-full bg-gradient-to-tr from-yellow-1 to-red-1'
                    ></span>
                  </div>
                  <div className='relative aspect-[170/227] h-20 2xl:h-24 rounded overflow-hidden flex-shrink-0'>
                    <Image
                      src={fitIn(banner.thumbnail_url || DummyLogo, 400)}
                      alt=''
                      layout='fill'
                      objectFit='cover'
                    />
                  </div>
                  <div className='-mt-2'>
                    <p className='mb-1.5 leading-none inline-block text-[10px] font-semibold text-transparent bg-clip-text bg-gradient-to-tr from-yellow-1 to-red-1'>
                      Articles
                    </p>
                    <p className='text-xs font-medium leading-tight 2xl:text-sm line-clamp-3'>
                      {banner.title}
                    </p>
                  </div>
                </div>
              )}
              {banner.content_type?.name === CONTENT_TYPES.OPINIONS && (
                <div
                  onClick={() => navigateSlide(index)}
                  className='relative flex items-center pt-3 cursor-pointer gap-x-3'
                >
                  <div className='absolute inset-0 h-0.5 overflow-hidden bg-white/10'>
                    <span
                      ref={navigationThumb.current[index]}
                      className='absolute inset-0 w-full h-full ease-linear -translate-x-full bg-gradient-to-tr from-yellow-1 to-red-1'
                    ></span>
                  </div>
                  <div className='relative aspect-[170/227] h-20 2xl:h-24 rounded overflow-hidden flex-shrink-0'>
                    <Image
                      src={fitIn(banner.thumbnail_url || DummyLogo, 400)}
                      alt=''
                      layout='fill'
                      objectFit='cover'
                    />
                  </div>
                  <div className='-mt-2'>
                    <p className='mb-1.5 leading-none inline-block text-[10px] font-semibold text-transparent bg-clip-text bg-gradient-to-tr from-yellow-1 to-red-1'>
                      Opinions
                    </p>
                    <p className='text-xs font-medium leading-tight 2xl:text-sm line-clamp-3'>
                      {banner.title}
                    </p>
                  </div>
                </div>
              )}
              {banner.content_type?.name === CONTENT_TYPES.SPOTLIGHTS && (
                <div
                  onClick={() => navigateSlide(index)}
                  className='relative flex items-center pt-3 cursor-pointer gap-x-3'
                >
                  <div className='absolute inset-0 h-0.5 overflow-hidden bg-white/10'>
                    <span
                      ref={navigationThumb.current[index]}
                      className='absolute inset-0 w-full h-full ease-linear -translate-x-full bg-gradient-to-tr from-yellow-1 to-red-1'
                    ></span>
                  </div>
                  <div className='relative aspect-[170/227] h-20 2xl:h-24 rounded overflow-hidden flex-shrink-0'>
                    <Image
                      src={fitIn(banner.thumbnail_url || DummyLogo, 400)}
                      alt=''
                      layout='fill'
                      objectFit='cover'
                    />
                  </div>
                  <div className='-mt-2'>
                    <p className='mb-1.5 leading-none inline-block text-[10px] font-semibold text-transparent bg-clip-text bg-gradient-to-tr from-yellow-1 to-red-1'>
                      Spotlights
                    </p>
                    <p className='text-xs font-medium leading-tight 2xl:text-sm line-clamp-3'>
                      {banner.title}
                    </p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className='relative mt-10 mb-16 lg:hidden'>
        <div className='flex items-center justify-end gap-x-1.5 !text-[10px]'>
          <div className='flex-1 relative h-0.5 overflow-hidden bg-white/10 rounded-full mr-4'>
            <span
              ref={paginationRef}
              className='absolute inset-0 w-full h-full ease-linear bg-gradient-to-tr from-yellow-1 to-red-1'
              style={{ transform: "translateX(-100%)" }}
            ></span>
          </div>
          <button
            type='button'
            ref={prevRef}
            className='inline-flex items-center px-3 py-1 font-medium tracking-wider uppercase transition border rounded-md bg-white/5 border-white/5 hover:bg-white/10 hover:text-white text-white/40'
          >
            <ArrowLeft className='mr-2 text-base -ml-0.5' />
            Prev
          </button>
          <button
            type='button'
            ref={nextRef}
            className='inline-flex items-center px-3 py-1 font-medium tracking-wider uppercase transition border rounded-md bg-white/5 border-white/5 hover:bg-white/10 hover:text-white text-white/40'
          >
            Next
            <ArrowRight className='ml-2 text-base -mr-0.5' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CarouselLatest;

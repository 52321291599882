import { useState, useEffect } from "react";

function useWindowSize() {
  const [size, setSize] = useState({ width: 360, height: 640 });

  useEffect(() => {
    function resize() {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }

    window.addEventListener("resize", resize);
    resize();

    return (_) => window.removeEventListener("resize", resize);
  }, []);

  return size;
}

export default useWindowSize;

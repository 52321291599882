import React from "react";

import logoAlgebra from "@/assets/logos/logo-algebra.svg";
import logoAntler from "@/assets/logos/logo-antler.svg";
import logoArowana from "@/assets/logos/logo-arowana.png";
import logoConsensys from "@/assets/logos/logo-consensys.svg";
import logoDiriyah from "@/assets/logos/logo-diriyah.svg";
import logoDPWorld from "@/assets/logos/logo-dpworld.svg";
import logoDubaiChamber from "@/assets/logos/logo-dubai-chamber.png";
import logoDubaiElectricity from "@/assets/logos/logo-dubai-electricity.webp";
import logoDubaiFuture from "@/assets/logos/logo-dubai-future.png";
import logoEFG from "@/assets/logos/logo-efg.png";
import logoFoodics from "@/assets/logos/logo-foodics.svg";
import logoGlobalventures from "@/assets/logos/logo-globalventures.svg";
import logoHungerstation from "@/assets/logos/logo-hungerstation.svg";
import logoImpact from "@/assets/logos/logo-impact.svg";
import logoMEVP from "@/assets/logos/logo-mevp.svg";
import logoNuwa from "@/assets/logos/logo-nuwa.svg";
import logoSaudia from "@/assets/logos/logo-saudia.png";
import logoVenturesouq from "@/assets/logos/logo-venturesouq.png";
import logoWaed from "@/assets/logos/logo-waed.png";
import logoMamo from "@/assets/logos/logo-mamo.svg";
import logoDreamgames from "@/assets/logos/logo-dreamgames.svg";
import logoPureharvest from "@/assets/logos/logo-pureharvest.svg";
import logoG42 from "@/assets/logos/logo-g42.svg";
import Image from "next/image";
import Marquee from "react-fast-marquee";

const trusted_logos = [
  { src: logoDubaiChamber, height: "h-16" },
  { src: logoWaed, height: "h-10" },
  { src: logoDubaiElectricity, height: "h-9 lg:h-12" },
  { src: logoConsensys, height: "h-7 lg:h-10" },
  { src: logoDPWorld, height: "h-7 lg:h-10" },
  { src: logoFoodics, height: "h-4 lg:h-6" },
  { src: logoDubaiFuture, height: "h-6 lg:h-9" },
  { src: logoHungerstation, height: "h-7 lg:h-10" },
  { src: logoSaudia, height: "h-9 lg:h-12" },
  { src: logoVenturesouq, height: "h-3 lg:h-5" },
  { src: logoGlobalventures, height: "h-5 lg:h-8" },
  { src: logoAntler, height: "h-7 lg:h-9" },
  { src: logoArowana, height: "h-6 lg:h-9" },
  { src: logoMEVP, height: "h-6 lg:h-8" },
  { src: logoAlgebra, height: "h-8 lg:h-12" },
  { src: logoDiriyah, height: "h-4 lg:h-6" },
  { src: logoNuwa, height: "h-10 lg:h-14" },
  { src: logoEFG, height: "h-4 lg:h-6" },
  { src: logoImpact, height: "h-6 lg:h-9" },
  { src: logoMamo, height: "h-6 lg:h-9" },
  { src: logoDreamgames, height: "h-6 lg:h-9" },
  { src: logoPureharvest, height: "h-6 lg:h-10" },
  { src: logoG42, height: "h-6 lg:h-10" },
];

export default function TrustedLogo() {
  return (
    <div className='my-10 lg:my-20'>
      <p className='mb-5 text-lg font-medium text-center lg:mb-8'>Trusted by</p>
      <Marquee>
        {trusted_logos.map((logo, index) => (
          <Image
            key={index}
            src={logo.src}
            alt='Slider Image'
            className={`w-auto px-3 lg:px-8 ${logo.height ?? "h-6 lg:h-7"}`}
            width={85}
            height={50}
          />
        ))}
      </Marquee>
    </div>
  );
}

import Carousel from "@/components/slider/Carousel";
import SpotlightCard from "../items/SpotlightCard";

function CarouselSpotlight({
  title,
  showMoreText,
  showMoreLink,
  data,
  cardHeight,
}) {
  return (
    <Carousel
      title={title}
      showMoreText={showMoreText}
      showMoreLink={showMoreLink}
    >
      {data.map((item, index) => (
          <SpotlightCard
            data={item}
            cardHeight={cardHeight}
            key={`spotlight-${item.uuid}`}
          />
        ))}
    </Carousel>
  );
}
export default CarouselSpotlight;

import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import Image from "next/image";

const FeaturedSlider = ({ logos }) => {
  return (
    <div className='relative w-full'>
      <Swiper
        modules={[Autoplay]}
        slidesPerView={"auto"}
        autoplay={
          logos.length > 13
            ? {
                delay: 2000,
              }
            : false
        }
        loop={logos.length > 13}
        spaceBetween={40}
        className=''
      >
        {logos.map((logo, index) => (
          <SwiperSlide key={index} className='!w-auto'>
            <Image
              src={logo}
              alt='Slider Image'
              className='h-6 lg:h-7'
              width={85}
              height={50}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='absolute top-0 right-0 z-10 w-48 h-full select-none bg-gradient-to-r from-transparent to-background'></div>
    </div>
  );
};

export default FeaturedSlider;

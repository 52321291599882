import dayjs from "dayjs";

export function formatDate(date, format, parsedFormat) {
  if (typeof date == "object" || !parsedFormat) {
    return dayjs(date).format(format);
  }

  return dayjs(date, parsedFormat).format(format);
}

export function isAfter(start, end) {
  return dayjs(start).isAfter(end);
}

export function fromNow(date) {
  var relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime)
  return dayjs(date).fromNow();
}

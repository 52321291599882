import { Play } from "@phosphor-icons/react";
import { formatDate } from "@/utils/datetime";
import Link from "next/link";
import { useEffect, useState } from "react";
import useWindowSize from "@/hooks/useWindowSize";
import { fitIn } from "@/utils/imageHandler";
import Image from "next/legacy/image";

const LatestVideo = ({ latest_video }) => {
  const [showVideo, setShowVideo] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    setTimeout(() => {
      if (size.width > 1024) {
        setShowVideo(true);
      } else {
        setShowVideo(false);
      }
    }, 200);
  }, [size]);
  return (
    <div className='relative flex flex-col h-full grid-cols-12  lg:grid'>
      <div className='self-center col-span-4 col-start-1 -mt-4 lg:pt-20 row-span-full'>
        <div className='relative z-10 lg:max-w-4xl 3xl:max-w-6xl'>
          <div className='flex mb-2 lg:mb-4 text-[10px]'>
            <span className='font-bold tracking-widest text-transparent uppercase bg-clip-text bg-gradient-to-r from-yellow-1 to-red-1'>
              Latest Video
            </span>
            <span className='pl-3 ml-3 font-medium tracking-wider uppercase border-l opacity-50 border-white/40'>
              {formatDate(latest_video.publish_date, "DD MMMM YYYY")}
            </span>
          </div>
          <p className='text-3xl font-bold lg:text-4xl 2xl:text-5xl font-display'>
            {latest_video.title}
          </p>
          <p className='mt-3 text-sm leading-relaxed 2xl:text-base opacity-80 2xl:mt-6'>
            {latest_video.meta_desc}
          </p>
          <Link legacyBehavior href={`/videos/${latest_video.slug}`}>
            <a className='inline-flex items-center px-3.5 py-2.5 text-sm font-medium leading-none rounded-md bg-gradient-to-r from-yellow-1 to-red-1 gap-x-2 mt-5 lg:mt-8'>
              <Play weight='fill' className='text-lg' />
              <span className='whitespace-nowrap'>Watch Video</span>
            </a>
          </Link>
          {/* <Link legacyBehavior href={`/videos/${latest_video.slug}`}>
            <a className='relative inline-flex items-center justify-start mt-4 space-x-3 lg:mt-0'>
              <div className='relative'>
                <div className='inline-flex items-center justify-center w-12 h-12 rounded-full lg:w-16 lg:h-16 pulse bg-gradient-to-r from-orange-1 to-red-1'>
                  <Play size={24} weight='fill'></Play>
                </div>
              </div>
              <div className='flex flex-col text-left'>
                <span className='text-base font-medium tracking-wide uppercase lg:text-lg'>
                  Play
                </span>
                <span className='text-xs tracking-wide opacity-60'>Video</span>
              </div>
            </a>
          </Link> */}
        </div>
      </div>
      <div className='relative self-center flex-1 order-first w-full col-span-7 col-start-6 lg:order-last lg:aspect-video lg:row-span-full'>
        {showVideo ? (
          <video
            autoPlay
            loop
            muted
            className='hidden object-cover w-full h-full lg:block'
            poster={fitIn(latest_video.thumbnail_url, 600)}
            preload='none'
          >
            <source
              id='video'
              src={latest_video.video_url}
              type='video/mp4'
            />
          </video>
        ) : (
          <Image
            src={fitIn(latest_video.thumbnail_url, 600)}
            alt=''
            layout='fill'
            objectFit='cover'
            className='lg:hidden'
          />
        )}
        <span className='absolute inset-0 w-full h-full bg-gradient-to-b from-background from-[0%] via-transparent to-background'></span>
        <span className='absolute inset-0 w-full h-full bg-gradient-to-r from-background from-[0%] via-transparent to-background'></span>
      </div>
    </div>
  );
};

export default LatestVideo;

import LucidMascot from "@/assets/images/lucid-mascot.png";
import NotFound from "@/assets/images/not-found.png";
import trySupport from "@/assets/images/try-it-support.svg";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { AISearch } from "@/services/api/SearchTool";
import { MagnifyingGlass } from "@phosphor-icons/react/dist/ssr";
import Image from "next/image";
import { useEffect, useState } from "react";
import Typewriter from "typewriter-effect";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import { Skeleton } from "../ui/skeleton";

import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import axios from "axios";
import { HardDrive } from "lucide-react";
import Link from "next/link";

gsap.registerPlugin(useGSAP);

const startups = {
  keyword: "Saudi arabia most popular startup",
  items: [
    {
      name: "stc Pay",
      support_text: "Riyadh, Saudi Arabia",
      logo_url:
        "https://lucidityinsights.com/_next/image?url=https%3A%2F%2Fcdn.lucidityinsights.com%2Ffit-in%2F400x400%2Ffilters%3Aformat(.webp)%2Fuploads%2Fstartups%2Fstc-pay.jpg&w=3840&q=75",
      tag: "Startup",
    },
    {
      name: "Nana Direct",
      support_text: "Riyadh, Saudi Arabia",
      logo_url:
        "https://lucidityinsights.com/_next/image?url=https%3A%2F%2Fcdn.lucidityinsights.com%2Ffit-in%2F400x400%2Ffilters%3Aformat(.webp)%2Fuploads%2Fstartups%2Fnana-direct.jpg&w=3840&q=75",
      tag: "Startup",
    },
    {
      name: "Foodics",
      support_text: "Riyadh, Saudi Arabia",
      logo_url:
        "https://lucidityinsights.com/_next/image?url=https%3A%2F%2Fcdn.lucidityinsights.com%2Ffit-in%2F400x400%2Ffilters%3Aformat(.webp)%2Fuploads%2Fstartups%2Ffoodics.jpg&w=3840&q=75",
      tag: "Startup",
    },
  ],
  related: {
    name: "Radisson Hotel Group on Working Towards Sustainable Tourism",
    support_text: "By Anamika Patel, 03 January 2023",
    logo_url:
      "https://lucidityinsights.com/_next/image?url=https%3A%2F%2Fcdn.lucidityinsights.com%2Ffit-in%2F1200x1200%2Ffilters%3Aformat(.webp)%2Fuploads%2Fmena-sustainability-innovation%2Fspotlights%2Fradisson%2Fcover-1.jpg&w=3840&q=75",
    tag: "Spotlight",
  },
};
const investors = {
  keyword: "Investor in Egypt",
  items: [
    {
      name: "Endure Capital",
      support_text: "Cairo, Egypt",
      logo_url:
        "https://lucidityinsights.com/_next/image?url=https%3A%2F%2Fcdn.lucidityinsights.com%2Ffit-in%2F400x400%2Ffilters%3Aformat(.webp)%2Fuploads%2Finvstors%2Fmena%2Fendure-capital.jpg&w=3840&q=75",
      tag: "Investor",
    },
    {
      name: "Disruptech Ventures",
      support_text: "Cairo, Egypt",
      logo_url:
        "https://lucidityinsights.com/_next/image?url=https%3A%2F%2Fcdn.lucidityinsights.com%2Ffit-in%2F400x400%2Ffilters%3Aformat(.webp)%2Fuploads%2Finvstors%2Fmena%2Fdisruptech-ventures.jpg&w=3840&q=75",
      tag: "Investor",
    },
    {
      name: "Algebra Ventures",
      support_text: "Cairo, Egypt",
      logo_url:
        "https://lucidityinsights.com/_next/image?url=https%3A%2F%2Fcdn.lucidityinsights.com%2Ffit-in%2F400x400%2Ffilters%3Aformat(.webp)%2Fuploads%2Finvstors%2Fmena%2Falgebra-ventures.jpg&w=3840&q=75",
      tag: "Investor",
    },
  ],
  related: {
    name: "Egypt's Startup Ecosystem Hit Hard Times in 2023, Here's What's Happening",
    support_text: "By Erika Masako Welch, 26 April 2024",
    logo_url:
      "https://lucidityinsights.com/_next/image?url=https%3A%2F%2Fcdn.lucidityinsights.com%2Ffit-in%2F1200x1200%2Ffilters%3Aformat(.webp)%2Fuploads%2Fegypt-2024%2Farticles%2Fconclusion.jpg&w=3840&q=75",
    tag: "Article",
  },
};
const articles = {
  keyword: "Article about UAE",
  items: [
    {
      name: "Meet the Most Active Investors in Saudi Arabia",
      support_text: "By Erika Masako Welch, 28 February 2023",
      logo_url:
        "https://lucidityinsights.com/_next/image?url=https%3A%2F%2Fcdn.lucidityinsights.com%2Ffit-in%2F1200x1200%2Ffilters%3Aformat(.webp)%2Fuploads%2Fsaudi-venture%2Farticles%2Finvestors%2Fcover.jpg&w=3840&q=75",
      tag: "Article",
    },
    {
      name: "The Top 10 Most Funded Startups in the UAE in 2023",
      support_text: "By Nazmia Nassereddine, 23 July 2024",
      logo_url:
        "https://lucidityinsights.com/_next/image?url=https%3A%2F%2Fcdn.lucidityinsights.com%2Ffit-in%2F1200x1200%2Ffilters%3Aformat(.webp)%2Fuploads%2Farticles%2F2024%2F0723_10-uae-startups%2Fcover.jpg&w=3840&q=75",
      tag: "Article",
    },
    {
      name: "10 Graphs You need to see to understand Egypt’s Startup Ecosystem",
      support_text: "By Ashok Raman, 20 March 2024",
      logo_url:
        "https://lucidityinsights.com/_next/image?url=https%3A%2F%2Fcdn.lucidityinsights.com%2Ffit-in%2F1200x1200%2Ffilters%3Aformat(.webp)%2Fuploads%2Fegypt-2024%2Farticles%2F10-graphs%2Fcover.jpg&w=3840&q=75",
      tag: "Article",
    },
  ],
  related: {
    name: "Société d’Articles Hygiéniques",
    support_text: "Tunis, Tunisia",
    logo_url:
      "https://lucidityinsights.com/_next/image?url=https%3A%2F%2Fcdn.lucidityinsights.com%2Ffit-in%2F400x400%2Ffilters%3Aformat(.webp)%2Fuploads%2Fstartups%2Fsociete.webp&w=3840&q=75",
    tag: "Startup",
  },
};

const CONTENT_TYPE_MAP = {
  "News": "news",
  "Articles": "articles",
  "Videos": "videos",
  "Podcast Episodes": "podcasts-masterclasses",
  "Opinions": "opinions",
  "Spotlights": "spotlights",
  "Infobytes": "infobytes",
  "Special Reports": "reports",
};

export default function SearchTool() {
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [currentView, setCurrentView] = useState(startups);
  const [relatedResults, setRelatedResults] = useState(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearch = async () => {
    if (inputValue.length > 2) {
      setIsLoading(true);
      try {
        const [aiResponse, converseaseResponse] = await Promise.all([
          AISearch({
            params: { query: inputValue },
          }),
          axios.post("https://api.conversease.com/search", {
            query: inputValue,
          }),
        ]);

        // Handle combined search results
        if (aiResponse.data.functionCalled === "searchBoth") {
          const combinedResults = {
            ...aiResponse.data,
            result: {
              data: [
                ...(aiResponse.data.result.startups?.data || []).map(
                  (item) => ({
                    ...item,
                    searchType: "Startup",
                  })
                ),
                ...(aiResponse.data.result.investors?.data || []).map(
                  (item) => ({
                    ...item,
                    searchType: "Investor",
                  })
                ),
              ],
            },
          };
          setSearchResults(combinedResults);
        } else {
          setSearchResults(aiResponse.data);
        }

        setRelatedResults(converseaseResponse.data.results);
      } catch (error) {
        console.error("Error fetching search results:", error);
        setSearchResults(null);
        setRelatedResults(null);
      }
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    if (inputValue === "") {
      setSearchResults(null);
    }
  }, [inputValue]);

  const renderSearchResults = () => {
    if (isLoading) {
      return <div className='p-4 text-center'>Loading...</div>;
    }

    if (searchResults === null) {
      return null; // Don't show anything if no search has been performed yet
    }

    if (
      !searchResults.result ||
      (searchResults.result.data || searchResults.result).length === 0
    ) {
      return (
        <div className='flex flex-col items-center justify-center h-full max-w-3xl p-6 mx-auto text-sm text-center'>
          <div className='relative h-20 aspect-square lg:h-24'>
            <Image src={NotFound.src} alt='NotFound Image' layout='fill' />
          </div>
          <p className='text-base font-medium'>Oops, It is Empty Here...</p>
          <p className='mt-2 opacity-60'>
            Your search &quot;{inputValue}&quot; did not match any of our
            contents. <br /> Please try again with different keywords
          </p>
        </div>
      );
    }

    const items = searchResults.result.data || searchResults.result;
    const functionCalled = searchResults.functionCalled;
    const parametersData = Object.entries({
      ...searchResults.parameters,
      q: searchResults.parameters.keyword,
    }).reduce((acc, [key, value]) => {
      if (value !== null && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
    delete parametersData.keyword;
    const parameters = parametersData;

    // Add this new section for related results
    const renderRelatedResults = () => {
      if (!relatedResults || relatedResults.length === 0) {
        return null;
      }

      return (
        <div className='pb-4'>
          <p className='px-4 py-3 text-sm font-medium bg-black border-b border-black/10 bg-black/5'>
            Related Results for &quot;{inputValue}&quot;
          </p>
          <div className='divide-y divide-background/10'>
            {relatedResults.map((item, index) => (
              <Link
                type='button'
                key={index}
                className='flex items-center justify-between w-full px-4 py-3 text-left transition cursor-pointer hover:bg-black/5'
                href={`/${CONTENT_TYPE_MAP[item.content_type?.name]}/${item.slug}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <div className='flex items-center gap-3'>
                  <div className='relative h-10 overflow-hidden border rounded-md rounded-lg aspect-square border-background/20'>
                    <Image
                      src={
                        item.thumbnail_url ||
                        item.cover_image_url ||
                        "https://via.placeholder.com/400"
                      }
                      layout={"fill"}
                      objectFit={"cover"}
                      alt='Image'
                    />
                  </div>
                  <div className='flex-1'>
                    <p className='mb-1 font-semibold leading-snug tracking-tight lg:text-lg w-[90%] line-clamp-2'>
                      {item.title}
                    </p>
                    <p className='text-xs opacity-50'>{item.support_text}</p>
                  </div>
                </div>
                <Badge
                  variant='outline'
                  className='text-[10px] lg:text-sm text-orange-600 border-orange-400'
                >
                  {item.content_type.name === "Podcast Episodes"
                    ? "Podcasts"
                    : item.content_type.name === "Special Reports"
                    ? "Reports"
                    : item.content_type.name}
                </Badge>
              </Link>
            ))}
          </div>
          {/* {relatedResults.length > 3 && (
            <div className='mx-4 mt-2'>
              <Button className='w-full border text-sky-600 border-background/10 hover:bg-background/5'>
                Show More Related Results ({relatedResults.length})
              </Button>
            </div>
          )} */}
        </div>
      );
    };

    return (
      <div className='divide-y divide-background/10'>
        <div className='pb-4'>
          <p className='px-4 py-3 text-sm font-medium bg-black border-b border-black/10 bg-black/5'>
            Results for &quot;{inputValue}&quot;
          </p>
          <div className='divide-y divide-background/10'>
            {items.slice(0, 10).map((item, index) => (
              <Link
                type='button'
                key={index}
                className='flex items-center justify-between w-full px-4 py-3 text-left transition cursor-pointer hover:bg-black/5'
                href={
                  item.searchType === "Startup" || functionCalled === "searchStartup"
                    ? `/startups/${item.slug}-${item.id}`
                  : item.searchType === "Investor" || functionCalled === "searchInvestor"
                    ? `/investors/${item.slug}-${item.id}`
                  : item.content_type?.name &&
                  `/${CONTENT_TYPE_MAP[item.content_type?.name]}/${item.slug}`
                }
                target='_blank'
                rel='noopener noreferrer'
              >
                <div className='flex items-center flex-1 gap-3'>
                  <div className='relative h-10 overflow-hidden border rounded-md rounded-lg aspect-square border-background/20'>
                    <Image
                      src={
                        item.logo_url ||
                        item.thumbnail_url ||
                        item.cover_image_url ||
                        "https://via.placeholder.com/400"
                      }
                      layout={"fill"}
                      objectFit={"cover"}
                      alt='Image'
                    />
                  </div>
                  <div className='flex-1'>
                    <p className='mb-1 font-semibold leading-snug tracking-tight lg:text-lg w-[90%] line-clamp-2'>
                      {item.name || item.title}
                    </p>
                    <p className='text-xs opacity-50'>{item.support_text}</p>
                  </div>
                </div>
                <Badge
                  variant='outline'
                  className='text-[10px] lg:text-sm text-orange-600 border-orange-400'
                >
                  {item.searchType || // Use searchType for combined results
                    (functionCalled === "searchStartup"
                      ? "Startup"
                      : functionCalled === "searchInvestor"
                      ? "Investor"
                      : item.content_type?.name === "Podcast Episodes"
                      ? "Podcasts"
                      : item.content_type?.name === "Special Reports"
                      ? "Reports"
                      : item.content_type?.name)}
                </Badge>
              </Link>
            ))}
          </div>
          {items.length > 10 &&
            (functionCalled === "searchStartup" ||
              functionCalled === "searchInvestor" ||
              functionCalled === "searchContent") && (
              <div className='mx-4 mt-2'>
                <Link
                  href={
                    functionCalled === "searchStartup"
                      ? `/startups?${new URLSearchParams(
                          parameters
                        ).toString()}`
                      : functionCalled === "searchInvestor"
                      ? `/investors?${new URLSearchParams(
                          parameters
                        ).toString()}`
                      : functionCalled === "searchContent" && CONTENT_TYPE_MAP[parameters.contentTypeName]
                      ? `/${CONTENT_TYPE_MAP[parameters.contentTypeName]}?${new URLSearchParams(
                          {
                            q: parameters.search
                          }
                        ).toString()}`
                      : `#`
                  }
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Button className='w-full border text-sky-600 border-background/10 hover:bg-background/5'>
                    Show More Results
                  </Button>
                </Link>
              </div>
            )}
        </div>
        {renderRelatedResults()}
      </div>
    );
  };

  const previewSearchResults = () => {
    return (
      <div className='relative h-full bg-white divide-y text-background divide-background/10'>
        {previewLoading ? (
          <div className='pb-4'>
            <div className='px-4 py-3 text-sm font-medium bg-black border-b border-black/10 bg-black/5'>
              <Skeleton className='w-64 h-5' />
            </div>
            <div className='divide-y divide-background/10'>
              {[...Array(12)].map((e, i) => (
                <div
                  key={i}
                  className='flex items-center justify-between w-full px-4 py-3 text-left'
                >
                  <div className='flex items-start gap-3'>
                    <Skeleton className='w-10 h-10' />
                    <div className='flex-1'>
                      <Skeleton className='w-48 h-4 lg:w-72' />
                      <Skeleton className='w-24 h-3 mt-2 lg:w-32' />
                    </div>
                  </div>
                  <Skeleton className='flex-shrink-0 h-5 rounded-full w-14' />
                </div>
              ))}
            </div>

            <div className='mx-4 mt-2'>
              <Button className='w-full border text-sky-600 border-background/10 hover:bg-background/5'>
                Show More Results
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className='pb-4 box'>
              <p className='px-4 py-3 text-sm font-medium bg-black border-b border-black/10 bg-black/5'>
                Results for &quot;{currentView.keyword}&quot;
              </p>
              <div className='divide-y divide-background/10'>
                {currentView.items.map((item, index) => (
                  <div
                    key={index}
                    className='flex items-center justify-between w-full px-4 py-3 text-left'
                  >
                    <div className='flex items-start gap-3'>
                      <div className='relative h-10 overflow-hidden border rounded-md rounded-lg aspect-square border-background/20'>
                        <Image
                          src={item.logo_url}
                          layout={"fill"}
                          objectFit={"cover"}
                          alt='Image'
                        />
                      </div>
                      <div className='flex-1'>
                        <p className='mb-1 text-sm font-semibold leading-snug tracking-tight lg:text-base'>
                          {item.name}
                        </p>
                        <p className='text-xs opacity-50'>
                          {item.support_text}
                        </p>
                      </div>
                    </div>
                    <Badge
                      variant='outline'
                      className='flex-shrink-0 text-[10px] lg:text-xs text-orange-600 border-orange-400'
                    >
                      {item.tag}
                    </Badge>
                  </div>
                ))}
              </div>

              <div className='mx-4 mt-2'>
                <Button className='w-full border text-sky-600 border-background/10 hover:bg-background/5'>
                  Show More Results
                </Button>
              </div>
            </div>
            <div className='pb-4'>
              <p className='px-4 py-3 text-sm font-medium bg-black border-b border-black/10 bg-black/5'>
                Related results for &quot;{currentView.keyword}
                &quot;
              </p>
              <div className='divide-y divide-background/10'>
                <div className='flex items-center justify-between w-full px-4 py-3 text-left transition cursor-pointer hover:bg-black/5'>
                  <div className='flex items-start gap-3'>
                    <div className='relative h-10 overflow-hidden border rounded-md rounded-lg aspect-square border-background/20'>
                      <Image
                        src={currentView.related.logo_url}
                        layout={"fill"}
                        objectFit={"cover"}
                        alt='Image'
                      />
                    </div>
                    <div className='flex-1'>
                      <p className='mb-1 text-sm font-semibold leading-snug tracking-tight lg:text-base'>
                        {currentView.related.name}
                      </p>
                      <p className='text-xs opacity-50'>
                        {currentView.related.support_text}
                      </p>
                    </div>
                  </div>
                  <Badge
                    variant='outline'
                    className='flex-shrink-0 text-[10px] lg:text-xs text-orange-600 border-orange-400'
                  >
                    {currentView.related.tag}
                  </Badge>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className='container'>
      <div className='relative items-center h-full gap-24 overflow-hidden lg:flex bg-gradient-to-r from-red-1 to-yellow-1 rounded-xl'>
        <div className='p-5 lg:flex-1 lg:p-14 text-background'>
          <p className='mb-1 text-2xl font-semibold leading-tight tracking-tight lg:leading-tight lg:text-4xl font-display'>
            Discover Game-Changing Investors, Disruptive Startups, and Exclusive
            Insights on our Ecosystem
          </p>
          <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger asChild>
              <div className='relative w-full p-1 my-5 overflow-hidden rounded-full shadow-md bg-gradient-to-l from-purple-1 via-red-1 to-orange-1'>
                <button
                  type='button'
                  onClick={() => setIsOpen(true)}
                  className='w-full px-5 py-3 font-medium text-left bg-white rounded-full'
                >
                  <Typewriter
                    options={{
                      loop: true,
                      delay: 70,
                    }}
                    onInit={(typewriter) => {
                      typewriter

                        .typeString("Most funded startups in Saudi")
                        .callFunction(() => {
                          setPreviewLoading(false);

                          setCurrentView(startups);
                        })
                        .pauseFor(3000)
                        .callFunction(() => {
                          setTimeout(() => {
                            setPreviewLoading(true);
                          }, 300);
                        })
                        .deleteAll(30)
                        .typeString("Investor in Egypt")
                        .callFunction(() => {
                          setPreviewLoading(false);
                          setCurrentView(investors);
                        })
                        .pauseFor(3000)
                        .callFunction(() => {
                          setTimeout(() => {
                            setPreviewLoading(true);
                          }, 300);
                        })
                        .deleteAll(30)
                        .typeString("Article about UAE")
                        .callFunction(() => {
                          setPreviewLoading(false);
                          setCurrentView(articles);
                        })
                        .pauseFor(3000)
                        .callFunction(() => {
                          setTimeout(() => {
                            setPreviewLoading(true);
                          }, 300);
                        })
                        .deleteAll(30)
                        .callFunction(() => {
                          console.log(
                            "All strings were deleted, gonna loop now!"
                          );
                        })
                        .start();
                    }}
                  />
                </button>
              </div>
            </PopoverTrigger>
            <PopoverContent className='p-0 bg-white border rounded-lg popover-dropdown text-background border-background/10'>
              <div className='flex items-center gap-2.5 px-4 border-b border-background/10'>
                <MagnifyingGlass weight='bold' className='text-lg opacity-40' />
                <input
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  placeholder='Type to get more relevant results...'
                  className='w-full py-3 border-transparent bg-none placeholder-background/50 focus-visible:ring-0 focus:outline-none'
                />
                <span className='absolute hidden text-sm opacity-50 lg:block right-4'>
                  Press Enter to search
                </span>
              </div>
              <div className='max-h-[360px] overflow-y-auto -mt-px'>
                {renderSearchResults()}
              </div>
            </PopoverContent>
          </Popover>
          <p className='text-sm lg:text-base opacity-80'>
            We have the most comprehensive database of startup ecosystem content
            in the Middle East and Africa region. Don&apos;t believe us? Type
            anything from a Startup Name, an Investor Name, or even &quot;Most
            Funded Startups in Saudi&quot; to see what you get. Welcome to
            Lucidity Insights!
          </p>
        </div>

        <div className='relative lg:w-[39%]'>
          <div className='absolute h-32 aspect-[92/127] -left-40 bottom-48'>
            <Image
              src={trySupport.src}
              alt=''
              layout={"fill"}
              objectFit={"cover"}
            />
          </div>
          <div className='hidden lg:block top-1/2 -translate-y-[40%] flex-shrink-0 absolute w-36 -left-[90px]  rotate-[-22deg] aspect-[199/320]'>
            <Image
              src={LucidMascot.src}
              alt=''
              layout={"fill"}
              objectFit={"cover"}
            />
          </div>

          <div className='w-full h-[450px] overflow-hidden'>
            {previewSearchResults()}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Link from "next/link";
import Image from "next/legacy/image";
import { ArrowRight } from "@phosphor-icons/react";
import { formatDate } from "@/utils/datetime";
import { fitIn } from "@/utils/imageHandler";

const LatestNews = ({ data }) => {
  return (
    <div>
      <div className='container flex items-center space-x-5 leading-none text-white'>
        <span className='text-2xl font-medium lg:text-3xl font-display'>
          Latest News
        </span>
        <span className='flex-1 h-px bg-gradient-to-r from-yellow-1 to-red-1'></span>
        <Link legacyBehavior href='/news' passHref>
          <a className='group inline-flex items-center space-x-2.5 text-sm font-medium'>
            <span>Show All News</span>
            <ArrowRight
              size={20}
              color='#fff'
              className='duration-200 group-hover:translate-x-1 transition-translate'
            />
          </a>
        </Link>
      </div>
      <div className='container mt-5 lg:mt-8 lg:space-x-12 lg:flex lg:items-center'>
        <Link legacyBehavior href={`/news/${data[0].slug}`} passHref>
          <a className='relative flex flex-col justify-end flex-1 overflow-hidden bg-white/5 group'>
            <div className='relative w-full aspect-[16/8] 3xl:aspect-[16/5]'>
              <Image
                src={fitIn(data[0].thumbnail_url, 700)}
                layout='fill'
                objectFit='cover'
                alt={data[0].title}
                className='transition-transform duration-300 lg:group-hover:scale-110'
              />
            </div>
            <span className='hidden absolute lg:block inset-0 bg-gradient-to-b from-[#101010]/0 via-[#101010]/70 to-[#101010]'></span>
            <div className='flex-1 p-6 text-white lg:absolute lg:p-10'>
              <span className='inline-block text-xs font-semibold tracking-wide text-transparent uppercase bg-clip-text bg-gradient-to-r from-yellow-1 to-red-1'>
                {formatDate(data[0].publish_date, "DD MMMM YYYY")}
              </span>
              <div className='lg:max-w-[70%] 3xl:max-w-[50%]'>
                <p className='mt-2 text-xl font-bold lg:text-4xl font-heading lg:mt-4'>
                  {data[0].title}
                </p>
                <p className='mt-3 opacity-50 lg:mt-6 line-clamp-3'>
                  {data[0].meta_desc}
                </p>
              </div>
            </div>
          </a>
        </Link>
        <div className='mt-10 space-y-6 text-white lg:space-y-8 lg:w-1/3 lg:mt-0'>
          {data.slice(1, 4).map((item, index) => (
            <Link legacyBehavior href={`/news/${item.slug}`} passHref key={`news-${index}`}>
              <a className='flex items-start space-x-5 overflow-hidden group'>
                <div className='relative aspect-square w-[90px] lg:w-[100px]'>
                  <Image
                    src={fitIn(item.thumbnail_url, 400)}
                    layout='fill'
                    objectFit='cover'
                    alt={item.title}
                    className='transition-transform duration-300 lg:group-hover:scale-125'
                  />
                </div>
                <div className='flex-1 -mt-2.5'>
                  <span className='bg-clip-text text-[10px] text-transparent bg-gradient-to-r from-yellow-1 to-red-1 font-semibold uppercase tracking-wide inline-block'>
                    {formatDate(item.publish_date, "DD MMMM YYYY")}
                  </span>
                  <p className='mt-2 text-base font-bold leading-snug line-clamp-2 lg:text-xl font-heading'>
                    {item.title}
                  </p>
                  <p className='mt-2 text-sm opacity-50 lg:mt-3 line-clamp-2'>
                    {item.meta_desc}
                  </p>
                </div>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestNews;

import PlaceholderImage from "@/assets/images/lucidity-placeholder.png";

export function fitIn(url, width, height) {
  if (!url || typeof url !== "string" || !/^(http|https):\/\//i.test(url)) {
    return PlaceholderImage;
  }

  if (!width && !height) {
    return url;
  }

  // Parse the URL
  let urlObj;
  try {
    urlObj = new URL(url);
  } catch (error) {
    return PlaceholderImage;
  }

  let newUrl = url;

  // Check if the URL starts with the specified prefix
  if (
    urlObj.origin === "https://d1477w6a38me2o.cloudfront.net" ||
    urlObj.origin === "https://lucidityinsights.s3.eu-west-2.amazonaws.com"
  ) {
    // Replace the prefix with the new URL
    newUrl = url.replace(urlObj.origin, "https://cdn.lucidityinsights.com");
    try {
      urlObj = new URL(newUrl);
    } catch (error) {
      return PlaceholderImage;
    }
  }

  // Modify the pathname
  if (width && height) {
    urlObj.pathname = `/fit-in/${width}x${height}/filters:format(.webp)${urlObj.pathname}`;
  } else if (width) {
    urlObj.pathname = `/fit-in/${width}x${width}/filters:format(.webp)${urlObj.pathname}`;
  }

  // Return the modified URL
  return urlObj.toString();
}

import useWindowSize from "@/hooks/useWindowSize";
import { ArrowRight, CaretLeft, CaretRight } from "@phosphor-icons/react";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";

const Carousel = ({
  children,
  title,
  showMoreText,
  showMoreLink,
  spaceBetween = 16,
}) => {
  const containerRef = useRef(null);
  const swiperRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const screenSize = useWindowSize();
  const [margin, setMargin] = useState(0);

  useEffect(() => {
    const params = {
      slidesPerView: "auto",
      spaceBetween: spaceBetween,
      navigation: {
        nextEl: nextRef.current,
        prevEl: prevRef.current,
      },
      keyboard: true,
      freeMode: true,
      mousewheel: { forceToAxis: true, releaseOnEdges: false },
      injectStyles: [
        `
         :host .swiper {
            overflow-y: visible;
         }
      `,
      ],
    };
    Object.assign(swiperRef.current, params);
    swiperRef.current.initialize();
  }, []);
  return (
    <div>
      {title && (
        <div
          ref={containerRef}
          className='flex items-center space-x-5 leading-none text-white'
        >
          <span className='text-xl font-medium lg:text-3xl font-display'>
            {title}
          </span>
          <span className='flex-1 h-px bg-gradient-to-r from-yellow-1 to-red-1'></span>
          {showMoreLink && (
            <Link legacyBehavior href={showMoreLink} passHref>
              <a className='group inline-flex items-center space-x-2.5 text-sm font-medium'>
                <span>{showMoreText ?? "Show All"}</span>
                <ArrowRight
                  size={20}
                  color='#fff'
                  className='duration-200 group-hover:translate-x-1 transition-translate'
                />
              </a>
            </Link>
          )}
        </div>
      )}

      <div className='relative group'>
        <swiper-container
          ref={swiperRef}
          init='false'
          class={`${title ? "pt-3 lg:pt-6" : ""}`}
        >
          {children.map((item, index) => (
            <swiper-slide
              key={index}
              class='!w-auto origin-center transition duration-300 lg:hover:z-10'
            >
              {item}
            </swiper-slide>
          ))}
        </swiper-container>
        {children.length > 1 && (
          <>
            <div className='absolute top-0 left-0 z-10 items-center hidden w-16 h-full transition duration-300 -translate-x-full opacity-0 lg:flex group-hover:translate-x-0 group-hover:opacity-100 bg-gradient-to-r from-background via-background/70 to-transparent'>
              <button type='button' ref={prevRef}>
                <CaretLeft size={32} color='#fff' />
              </button>
            </div>
            <div className='absolute top-0 right-0 z-10 items-center justify-end hidden w-16 h-full pr-4 transition duration-300 translate-x-full opacity-0 lg:flex group-hover:translate-x-0 group-hover:opacity-100 bg-gradient-to-l from-background via-background/70 to-transparent'>
              <button type='button' ref={nextRef}>
                <CaretRight size={32} color='#fff' />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Carousel;

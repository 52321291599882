import { env } from "@/next.config";
import httpUtils from "@/utils/httpUtils";

export async function getSEOByPathname(pathname, config) {
    return httpUtils.get(`${env.API_VERSION}/seo/${pathname}`, config);
}

export async function getSEOByTitle(pathname, config) {
    return httpUtils.get(`${env.API_VERSION}/seo/title/${pathname}`, config);
}

import Carousel from "@/components/slider/Carousel";
import VideoCard from "../items/VideoCard";

function CarouselTopVideo({
  title,
  showMoreText,
  showMoreLink,
  data,
  videoHeight,
}) {
  return (
    <Carousel
      title={title}
      showMoreText={showMoreText}
      showMoreLink={showMoreLink}
      spaceBetween={40}
    >
      {data.map((item, index) => (
        <div key={`video-${item.uuid}`} className='flex items-center'>
          <p className='font-bold text-white/10 text-[240px] leading-none -mr-9'>
            {index + 1}
          </p>
          <VideoCard videoHeight={videoHeight} data={item} />
        </div>
      ))}
    </Carousel>
  );
}
export default CarouselTopVideo;
